class NavBarController {
  toggles: NodeListOf<HTMLElement>;

  menus: NodeListOf<HTMLElement>;

  currentMenu: HTMLElement | null = null;

  currentToggle: HTMLElement | null = null;

  constructor() {
    this.toggles = document.querySelectorAll(".nav-menu-toggle");
    this.menus = document.querySelectorAll(".nav-menu");
  }

  init() {
    this.toggles.forEach((toggle) => {
      toggle.addEventListener("keydown", (event) => {
        if (event.key !== "Enter") {
          return;
        }
        if (this.currentMenu) {
          this.currentMenu.classList.toggle("tw-invisible");
        }
        const targetMenu = toggle.dataset?.targetMenu;
        if (targetMenu) {
          const menu: HTMLElement | null = document.querySelector(
            `#${targetMenu}`,
          );
          if (menu) {
            menu.classList.toggle("tw-invisible");
            this.currentMenu = menu;
            this.currentToggle = toggle;
            toggle.setAttribute("aria-expanded", "true");
          }
        }
      });

      document.addEventListener("focusin", (e) => {
        const target = e.target as HTMLElement;
        if (!this.currentMenu?.contains(target)) {
          this.currentMenu?.classList.toggle("tw-invisible");
          this.currentMenu = null;
          this.currentToggle?.setAttribute("aria-expanded", "false");
        }
      });
    });

    this.menus.forEach((menu) => {
      menu.addEventListener("keydown", (event) => {
        if (event.key !== "Escape" || this.currentMenu !== menu) {
          return;
        }
        event.preventDefault();
        menu.classList.toggle("tw-invisible");
        this.currentMenu = null;
        this.currentToggle?.focus();
      });
    });
  }
}

const navBarController = new NavBarController();
navBarController.init();
